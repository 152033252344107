<p-overlayPanel #opInfoBot>
  <section [id]="selectCard.id"
           [class]="'bg-color-custom'"
           class="scale02  animate-fade-right animate-once m-4 max-w-sm  px-6 pt-6 pb-2 rounded-xl shadow-lg transform  hover:scale-100 transition duration-500"
  >
    <h3 [style.color]="selectCard.color"
        style="font-family: peace_sans,serif;"
        class="mb-3 text-md tracking-wide text-amber-600">
      <span *ngIf="selectCard.name">
         {{ selectCard.name }}
      </span>

      <span *ngIf="selectCard.srcIcon"
            [class]="'bg-color-custom' +' '+ 'border-[#4a0620]'"
            class="  absolute -top-3 right-0 rounded-full  border-4 ">
         <img
           class="rounded-lg w-[50px] h-[50px]"
           [src]="selectCard.srcIcon"
           [alt]="selectCard.name"
         >
       </span>

    </h3>

    <div class="relative">

      <img *ngIf="selectCard.srcPreview && selectCard.name"
           class="w-full rounded-xl"
           [src]="selectCard.srcPreview"
           [alt]="selectCard.name"
           [style.border]="'2px solid ' + selectCard.color +' '"
      />

      <p *ngIf="selectCard.status"
         [class]="'bg-amber-300'"
         class=" absolute top-0 text-zinc-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
        <span *ngIf="selectCard.status">
          {{ selectCard.status }}
        </span>
      </p>

      <p *ngIf="!selectCard.costDiscount"
         [class]="'bg-color-custom'"

         class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
      >


      </p>
      <p *ngIf="selectCard.costDiscount"
         [class]="'bg-amber-300'"
         class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
      >
        <span>
          <span>{{ selectCard.costDiscount }}</span>
        </span>
      </p>
          <app-gif-generator *ngIf="selectCard.gifsEnable "
                             [photos]="selectCard.favorites">
          </app-gif-generator>
    </div>
    <app-text-slice *ngIf="selectCard.desc"
                    [text]="selectCard.desc"
    ></app-text-slice>
    <ng-container>
      <div class="my-4">
        <div *ngIf="selectCard.cat"
             class="flex space-x-1 items-center"
        >
          <app-svg-icon></app-svg-icon>

          <p class="text-zinc-400">{{ selectCard.cat }}</p>
        </div>
        <div *ngIf="selectCard.duration"
             class="flex space-x-1 items-center"
        >
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="clockIcon "></i>
          </span>
          <p class="text-zinc-400">{{ selectCard.duration }}</p>
        </div>

        <div *ngIf="selectCard.painter"
             class="flex space-x-1 items-center"
        >
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
           <i [class]="brushIcon"></i>
          </span>
          <p class="text-zinc-400">+ Drawing trainer(painter)</p>
        </div>

        <div *ngIf="selectCard.array.length>2"
             class="flex space-x-1 items-center">

          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="listIcon"></i>
          </span>

          <p class="text-zinc-400">
          <span *ngIf="selectCard.statistic">
              {{ selectCard.statistic.countArrays }}
            </span>

            <span *ngIf="selectCard.continueEnable">

          </span>
          </p>
        </div>

        <div *ngIf="selectCard.level" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
           <i [class]="levelIcon"></i>
          </span>
          <p class="text-zinc-400">
            <span>{{ selectCard.level }}</span>
          </p>
        </div>


        <div *ngIf="selectCard.additionally" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
              <i [class]="folderIcon"></i>
          </span>
          <p class="text-zinc-400"> {{ selectCard.additionally }}</p>
        </div>


      </div>
    </ng-container>

    <div *ngIf="selectCard.info" class="font-bold text-xl mb-2">
      <p  class="text-amber-500 text-base">{{selectCard.info}}</p>
    </div>
  </section>
</p-overlayPanel>
<!--<app-stepper>

</app-stepper>-->
<div class="relative ">
  <div class=" z-30 fixed top-[55px] right-[0px]">
      <app-action *ngIf="cd.showActionsSales"
                  [card]="selectCard"
      ></app-action>
  </div>
  <nav
    class=" min-w-[200px] animate-fade-bottom animate-once animate-ease-in-out z-10 flex shrink-0 grow-0 justify-around border-t border-gray-200 bg-color-custom/50 p-1 shadow-lg backdrop-blur-lg   fixed bottom-3 left-[80px] rounded-lg border"
  >
    <div
      class="flex  flex-col items-center justify-center  rounded-md  bg-color-custom text-amber-600"
    >
      <button #targetEl
              (click)="opInfoBot.toggle($event,targetEl)"
              class="absolute -top-4 left-0 h-[25px] w-[200px] text-sm  bg-color-custom text-white rounded-full transition-transform transform hover:scale-105"
      >
        Дізнатися більше...
        <i class="fa-solid fa-info"></i>
      </button>
      <small class="  text-center text-md font-bold p-1">
             <span>
              {{ selectCard.name }} -
               {{ selectCard.cost }}
             </span>
      </small>
    </div>
  </nav>
</div>
