<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPageArtParty.seoTexts.h1 }}</h1>
  <h2>{{ dataPageArtParty.seoTexts.h2 }}</h2>
  <h3>{{ dataPageArtParty.seoTexts.h3 }}</h3>
  <p>{{ dataPageArtParty.seoTexts.text }}</p>
</div>
<p-overlayPanel #opShowContacts>
  <div
    class="relative bg-color-custom  "
  >
    <nav
      class="flex shrink-0 grow-0 justify-around gap-4 border-t border-gray-200 bg-color-custom/50 p-1 shadow-lg backdrop-blur-lg   min-h-[auto] min-w-[64px] flex-col rounded-lg border"
    >
        <ng-container *ngFor="let item of myData.menuTop.concat(myData.menuBottom).concat(myData.phones);let i = index">

          <a *ngIf="item.href" [target]="item.target"
             [href]="item.href"
             (click)="item.command(item,i)"
             class=" z-30 flex aspect-square min-h-[60px] w-[60px] flex-col items-center justify-center gap-1 rounded-md p-3 bg-color-custom text-amber-600"
          >
            <img *ngIf="item.srcPreview"
                 [src]="item.srcPreview" class="h-[60px] w-[60px]"
                 [alt]="item.label">
            <i *ngIf="item.icon && !item.srcPreview"
               style="font-size:15px" class=" mx-1"
               [class]="item.icon"
            ></i>

            <span *ngIf="item.label">{{item.label}}</span>
          </a>

        </ng-container>
    </nav>
  </div>
</p-overlayPanel>
<div class="leading-normal tracking-normal text-color-custom gradient"
     style="font-family: peace_sans,serif;">
  <!--Nav-->
  <nav id="header" class="fixed w-full z-30 top-0 text-color-custom gradient">
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
      <div class="pl-4 flex items-center">
        <a
          class="toggleColour text-color-custom no-underline hover:no-underline text-2xl lg:text-4xl" href="#">

          ART-PARTY
        </a>
        <div class="z-20 absolute  bottom-[5px] left-[50px] text-color-custom text-[10px]">
          15 років малюю для Вас!
        </div>
      </div>
      <div class="col-start-10 col-end-12  flex justify-end items-center mr-3">
        <div class="" id="navbar-sticky ">
          <ul class=" flex items-center justify-between w-full text-sm">

            <a  class="flex items-center">
              <img [src]="myData.personal.flagSrc"
                   class=" animate-bounce animate-infinite animate-duration-[2000ms] w-[15px] h-[10px ] mr-3" alt="">
              <span
                class="   text-color-custom self-center text-[12px]  whitespace-nowrap">
        {{ myData.personal.cityCountry }}
      </span>


            </a>
          </ul>
          <div  class="z-20 absolute font-bold  bottom-[5px] right-[5px] text-amber-200 text-[10px]">
          <span *ngIf="basketServices.statusSendMessege" >
              Заявка відправленна!
             <i [class]="telegramIcon"></i>
          </span>
            <span *ngIf="!basketServices.statusSendMessege" >
             Найближчим часом звяжусь з Вами!
             <i [class]="favoriteIcon"></i>
          </span>

          </div>

        </div>

      </div>
    </div>
    <hr class="border-b border-white opacity-25 my-0 py-0"/>
  </nav>
  <!--Hero-->
  <div class="pt-24">
    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <!--Left Col-->
      <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
        <p class="uppercase tracking-loose w-full"></p>
        <p style="font-family: peace_sans,serif;"
           class="my-4 text-5xl leading-tight">
          Шаржі по фото
        </p>
        <p style="font-family: peace_sans,serif;" class="leading-normal text-md mb-8">
          Ви можете замовити карикатури чи шаржі по фото
        </p>
        <button #targetShowContacts (click)="opShowContacts.toggle($event,targetShowContacts);"
          style="font-family: peace_sans,serif;"
                class="mx-auto lg:mx-0 hover:underline bg-color-custom  text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Контакты
        </button>
      </div>
      <!--Right Col-->
      <div class="w-full md:w-3/5 py-6 text-center">
<!--        <img class="w-full md:w-4/5 z-50" src="assets/hero.png"/>-->
      </div>
    </div>
  </div>
  <div class="relative -mt-12 lg:-mt-24">
    <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-2.000000, 44.000000)" fill="#4a0620" fill-rule="nonzero">
          <path
            d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
            opacity="0.100000001"></path>
          <path
            d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
            opacity="0.100000001"
          ></path>
          <path
            d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
            id="Path-4" opacity="0.200000003"></path>
        </g>
        <g transform="translate(-4.000000, 76.000000)" fill="#4a0620" fill-rule="nonzero">
          <path
            d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
          ></path>
        </g>
      </g>
    </svg>
  </div>
  <section class="bg-color-custom  border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <p style="font-family: peace_sans,serif;" class="w-full my-2 text-4xl leading-tight text-center text-color-custom">
        Виликі знижки та супер пропозиції :
      </p>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
            {{ portretInks.name }}
          </p>
          <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
            {{ portretInks.desc }}
            <br/>
            <br/>
          </p>

        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img [src]="portretInks.srcPreview"/>
          <button (click)="setSelect(portretInks)"
                  class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Перейти  - <app-cost
            *ngIf="portretInks.action"
            [cost]="portretInks.action"
            [showDiscount]="false"
            [clearCurrency]="false"
          ></app-cost>
          </button>
        </div>

      </div>
      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <img [src]="characterInks.srcPreview"/>
          <button (click)="setSelect(characterInks)"
                  class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Перейти -  - <app-cost
            *ngIf="characterInks.action"
            [cost]="characterInks.action"
            [showDiscount]="false"
            [clearCurrency]="false"
          ></app-cost>
          </button>
        </div>

        <div class="w-full sm:w-1/2 p-6 mt-6">
          <div class="align-middle">
            <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
              {{ characterInks.name }}
            </p>
            <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
              {{ characterInks.desc }}
              <br/>
              <br/>
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
            {{ characterInksColor.name }}
          </p>
          <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
            {{ characterInksColor.desc }}
            <br/>
            <br/>
          </p>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img [alt]="characterInksColor.name" [src]="characterInksColor.srcPreview"/>
          <button (click)="setSelect(characterInksColor)"
                  class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Перейти - <app-cost
            *ngIf="characterInksColor.cost"
            [cost]="characterInksColor.cost"
            [showDiscount]="true"
            [clearCurrency]="false"
          ></app-cost>
          </button>
        </div>

      </div>
      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <img [src]="siluet.srcPreview"/>
          <button (click)="setSelect(siluet)"
                  class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Перейти  -
            <app-cost
              *ngIf="siluet.cost"
              [cost]="siluet.cost"
              [showDiscount]="true"
              [clearCurrency]="false"
            ></app-cost>
          </button>
        </div>
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <div class="align-middle">
            <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
              {{ siluet.name }}
            </p>
            <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
              {{ siluet.desc }}
              <br/>
              <br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
           class="bg-color-custom  border-b py-8 bg-[length:50px_50px] bg-opacity-10  bg-repeat ">
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
      <h2 class="w-full my-2 text-5xl leading-tight text-center text-color-custom">
        Перелік усіх послуг:
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>

      <div
            [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
            class=" swiper mySwiper bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center"
        >
        <div class="swiper-wrapper">
          <ng-container
            *ngFor="let  card  of  dataPageArtas.getCards().concat(dataPageArtParty.getCards()); let last = last;">
            <ng-container *ngIf="card.sw === true || last === true ">
              <div class="swiper-slide">
        <!--        <app-card-artas
                  [card]="card"

                ></app-card-artas>-->

                <p class="w-full my-2 text-md leading-tight text-center text-color-custom ">
                  {{ card.name }}
                </p>

                <p-overlayPanel #opOrderByImage>
                  <app-order-by-image-form *ngIf="showOrderByCard "
                                           [selectModel]="card"
                                           (onClose)="showOrderByCard = false"
                  ></app-order-by-image-form>
                </p-overlayPanel>
                <img [src]="card.srcPreview"/>
                <button  (click)="setSelect(card)"
                  class=" left-1 absolute bottom-1 z-20  focus:ring-4  transform  active:scale-75 transition-transform mt-4 text-xl w-90 text-center  gradient py-1 px-1 rounded-lg shadow-lg"
                >
                                   <span class="ml-1 text-xl text-color-custom ">
                  <i [class]="photosIcon"></i>
                                     Перейти
                </span>
                </button>
                <!--<button #targetOrderByImage
                        (click)=" showOrderByCard =true; opOrderByImage.toggle($event,targetOrderByImage);"
                        class=" right-1 absolute bottom-1 z-20 focus:ring-4  transform  active:scale-75 transition-transform mt-4 text-xl w-90 text-center  gradient py-1 px-1 rounded-lg shadow-lg"
                >
                    <span class="ml-1 text-xl text-color-custom ">
                      <i [class]="basketIcon"></i>
                      <span>({{card.cost?card.cost:'договірна'}})</span>
                    </span>
                </button>-->
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>

  <section  [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
            class="bg-color-custom  border-b py-8 bg-[length:50px_50px] bg-opacity-10  bg-repeat " >
    <div class="container mx-auto px-2 pt-4 pb-12 text-color-custom">
      <p style="font-family: peace_sans,serif;"
         class="w-full my-2 text-5xl leading-tight text-center text-color-custom">
        Вартість
      </p>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
        <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-color-custom  mt-4">
          <div class="flex-1 bg-color-custom  text-color-custom rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;"
                 class="p-8 text-3xl text-center border-b-4">
              Приїзд
            </div>
            <ul class="w-full text-center text-sm">
              <ng-container
                *ngFor="let  card  of  dataPageArtParty.getCards(); let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class=" border-b py-4">
                    {{ card.name }}

                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom text-center">
              Договірна
              <span class="text-base">/ за ходину</span>
            </div>
            <div class="flex items-center justify-center">
              <button [routerLink]="'/'"
                      class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Зараз працюю тільки по фото
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-color-custom  mt-4 sm:-mt-6 shadow-lg z-10">
          <div class="flex-1 bg-color-custom  rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;"
                 class="w-full p-8 text-3xl text-color-custom  text-center">По фото
            </div>
            <div class="h-1 w-full gradient my-0 py-0 rounded-t"></div>
            <ul class="w-full text-center text-color-custom  text-sm">
              <ng-container
                *ngFor="let  card  of  dataPageArtas.getCards(); let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class=" border-b py-4">
                    {{ card.name }}
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom  text-center">
              від 100 грн
              <span class="text-base">/ за героя</span>
            </div>
            <div class="flex items-center justify-center">
              <button (click)="setSelect(dataPageArtas.getCards()[0])"
                      class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Перейти
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-color-custom  mt-4">
          <div class="flex-1 bg-color-custom  text-color-custom rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;" class="p-8 text-3xl text-center border-b-4">
              Передзамовлення на свята та заходи по фото
            </div>
            <ul class="w-full text-center text-sm">
              <ng-container
                *ngFor="let  card  of  [portretInks,characterInks,characterInksColor]; let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class="border-b py-4">{{ card.name }}</li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom text-center">
              від 500 грн
              <span class="text-base">/від ТЗ </span>
            </div>
            <div class="flex items-center justify-center">
              <button (click)="setSelect(dataPageArtas.getCards()[0])"
                      class="mx-auto lg:mx-0 hover:underline gradient text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Перейти
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <svg class="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
        <g class="wave" fill="#4a0620">
          <path
            d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
          ></path>
        </g>
        <g transform="translate(1.000000, 15.000000)" fill="#4a0620">
          <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
            <path
              d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
              opacity="0.100000001"></path>
            <path
              d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
              opacity="0.100000001"
            ></path>
            <path
              d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
              opacity="0.200000003"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <section class="container mx-auto text-center py-6 mb-12">
  <!--  <h2 class="w-full my-2 text-1xl leading-tight text-center text-color-custom">
      <app-action [showCost]="false"></app-action>
    </h2>-->
    <div class="w-full mb-4">
      <div class="h-1 mx-auto bg-color-custom  w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
    </div>
    <h3 class="my-4 text-3xl leading-tight">
      Завжди до ваших послуг
    </h3>
    <button #targetShowContacts2 (click)="opShowContacts.toggle($event,targetShowContacts2);"
      class="mx-auto lg:mx-0 hover:underline bg-color-custom  text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
      Контакти
    </button>
  </section>
  <footer class="bg-color-custom ">
  </footer>


</div>
