import {NgModule} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {PageArtasComponent} from "./pages/pages-jome/page-artas/page-artas.component";
import {PageArtasProductsComponent} from "./pages/pages-jome/page-artas/page-artas-products/page-artas-products.component";
import {PageArtasServices} from "./services/pages/page-artas.services";
import {PageHomeComponent} from "./pages/pages-jome/page-art-party/page-home/page-home.component";

const routes: Routes = [
  {
    path: "",
    component: PageHomeComponent,
    pathMatch: 'full',
  },
  {
    path: ":slug",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },

   {
    path: "**",
    component: PageHomeComponent,
  }
 /* {
    path: "",
    component: PageArtasComponent,
    pathMatch: 'full',

  },
  {
    path: ":slug",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },

  {
    path: "**",
    component: PageArtasComponent,
  }*/


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
