<div *ngIf="showAction"

     class="relative text-color-custom bg-color-custom text-center p-2  shadow-lg  rounded-md"
>
  <!--animate-bounce-->
  <span class="absolute -top-[5px] right-1 z-30 bg-zinc-600/60 text-zinc-100 text-[10px] p-[3px] rounded-md">
    діє до {{getDateWithOffset(2)}}
  </span>
  <ng-container *ngIf="!card.action">
  <span [class]="styleAction"  class="font-bold  rounded-md p-[3px] shadow-amber-800 shadow-2xl ">
    {{textAction}}
  </span>
  <span *ngIf="showCost" [class]="styleAction" class="  ml-[1px] font-bold  rounded-md p-[3px] shadow-amber-800 shadow-2xl  ">
    <app-cost
      *ngIf="card.cost"
      [cost]="card.cost"
      [showDiscount]="cd.showActionsSales"
      [clearCurrency]="false"
    ></app-cost>
  </span>
  </ng-container>
  <ng-container *ngIf="card.action">
  <span [class]="styleAction"  class="font-bold  rounded-md p-[3px] shadow-amber-800 shadow-2xl ">
    Супер ціна!
  </span>
  <span *ngIf="showCost" [class]="styleAction" class="  ml-[1px] font-bold  rounded-md p-[3px] shadow-amber-800 shadow-2xl  ">
    <app-cost
      [cost]="card.action"
      [showDiscount]="false"
      [clearCurrency]="false"
    ></app-cost>
  </span>
  </ng-container>


</div>
