import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {MyDataServices} from "../../../../services/my-data.services";
import {CdServices} from "../../../../services/common/cd.services";
import {windowHeight} from 'src/app/traits/window-tools';
import {
  brushIcon,
  clockIcon,
  folderIcon,
  levelIcon,
  listIcon
} from 'src/app/traits/icons-tools';
import {GifGeneratorComponent} from "../../../../components/image/gif-generator/gif-generator.component";
import {TextSliceComponent} from "../../../../components/text-slice/text-slice.component";
import {SvgIconComponent} from "../../../../components/image/svg-icon/svg-icon.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ActionComponent} from "../action/action.component";
import {reInit} from 'src/app/traits/datetime-tools';

@Component({
  selector: 'app-product-more-artas',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ScrollPanelModule,
    GifGeneratorComponent,
    TextSliceComponent,
    SvgIconComponent,
    SvgIconComponent,
    OverlayPanelModule,
    ActionComponent,
  ],
  templateUrl: './product-more-artas.component.html',
})
export class ProductMoreArtasComponent {
  @Input() public cards: any;
  @Input() public selectCard: any;
  @Output() onSelectCard = new EventEmitter();

  protected readonly windowHeight = windowHeight;
  protected readonly levelIcon = levelIcon;
  protected readonly brushIcon = brushIcon;
  protected readonly listIcon = listIcon;
  protected readonly clockIcon = clockIcon;
  protected readonly folderIcon = folderIcon;

  constructor(public cd: CdServices, public myData: MyDataServices,) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  getDiscount(cost, discount: any = 20) {
    cost = cost.replace(/\D/g, '')
    return cost - (cost * discount / 100);
  }


}
