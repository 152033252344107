import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {
  ActivatedRoute,
  Router,
  RouterLink
} from "@angular/router";
import {PageArtPartyServices} from "../../../../services/pages/page-art-party.services";
import {PageArtasServices} from "../../../../services/pages/page-artas.services";
import {PageIllustrationsServices} from "../../../../services/pages/page-illustrations.services";
import {
  basketIcon,
  favoriteIcon,
  photosIcon,
  telegramIcon
} from 'src/app/traits/icons-tools';
import {MyDataServices} from "../../../../services/my-data.services";
import {BasketServices} from "../../../../services/common/basket.services";
import {OrderByImageFormComponent} from "../../page-artas/order-by-image-form/order-by-image-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ActionComponent} from "../../page-artas/action/action.component";
import {CardArtasComponent} from "../../page-artas/card-artas/card-artas.component";
import {CostComponent} from "../../page-artas/cost/cost.component";

declare const Swiper;
@Component({
  selector: 'app-home.component.html',
  templateUrl: './page-home.component.html',
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    OrderByImageFormComponent,
    OverlayPanelModule,
    ActionComponent,
    CardArtasComponent,
    CostComponent,
  ],
})
export class PageHomeComponent {
 public portretInks:any =this.getProductPortretInk('f4fc4lb4c') ;
 public characterInks:any =this.getProductPortretInk('w0l3k0kzl') ;
 public characterInksColor:any =this.getProductPortretInk('i2d3f6o39') ;
 public siluet:any =this.getProductPortretInk('ahkazyxck') ;
 public showOrderByCard: boolean;
  protected readonly basketIcon = basketIcon;
  protected readonly photosIcon = photosIcon;

  constructor(
    public dataPageArtas: PageArtasServices,
    public dataPageArtParty: PageArtPartyServices,
    public myData:MyDataServices,
    public basketServices: BasketServices,
    private router: Router,

             ) {}
ngAfterViewInit(){
 this.initSwiper();
}
initSwiper(){
  var swiper = new Swiper(".mySwiper", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });
}
public getProductPortretInk(id:any){
 return  this.dataPageArtas.findCard(id);

}
  setSelect(card: any) {
    // 'zakazat-sharj-po-foto'
    // this.card =card;
    // this.findInBox();
    this.router.navigate(['/',card.id]);
    window.scrollTo(0, 0);

  }
  protected readonly telegramIcon = telegramIcon;
  protected readonly favoriteIcon = favoriteIcon;
}
