import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgForOf,
  NgIf
} from "@angular/common";
import {
  getDiscount,
  randomId
} from 'src/app/traits/string-tools';
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ServerOrderServices} from "../../../../services/servers/server-order.services";
import {BasketServices} from "../../../../services/common/basket.services";
import {CdServices} from "../../../../services/common/cd.services";

@Component({
  selector: 'app-order-by-image-form',
  templateUrl: './order-by-image-form.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    JsonPipe,
  ],
})
export class OrderByImageFormComponent implements OnInit, AfterViewInit {
  @Output() onSubmit = new EventEmitter();
  @Input() nameOrder: any;
  @Input() formStorageName: any = 'form';
  @Input() srcPreview: any;
  @Input() showOrderCard: any = true;
  public form: any = {
    nameClient: '',
    phone: '',
    comment: '',
    validation: true,
    checked: [],
    stringChecked: '', // setName: this.cd.set.name,
    // setDesc: this.cd.set.desc,
    // srcPreview: this.cd.set.srcPreview,
  };
  public showCardOrder: boolean = true;
  @Input() selectImageModel: any;
  @Input() selectModel: any;
  @Output() onClose = new EventEmitter();
  @Input()  typeOrder: any ='Хочу подібний';

  constructor(public cd:CdServices,public serverOrder: ServerOrderServices, public basketServices: BasketServices,) {
  }

  ngOnInit() {
    this.initFormStorage();

  }

  checkedAdds(checkedList: any) {
    let stringChecked = '|';
    checkedList.map((check: any) => {
      return stringChecked += check.name + '|';
    });
    // let sringChecked = checkedList.join('|');
    console.log(stringChecked, 'this.checked');
    this.form.stringChecked = stringChecked;
  }

  submit() {
    let orderCard: any = {
      url: window.location.href,
      type: this.typeOrder,
      name: 'Назва послуги - '+ this.selectModel.name,
      desc:'Опис послуги - ' + this.selectModel.desc,
      srcPreview: this.typeOrder === 'Хочу подібний'?this.selectImageModel.srcPreview : this.selectModel.srcPreview,
      cost: 'Повна вартість - '+this.selectModel.cost,
      discount: 'Зі знижкою - '+  this.selectModel.action ? this.selectModel.action : getDiscount(this.selectModel.cost)+' грн',
      action:this.cd.showActionsSales?'АКЦИЯ-'+(this.selectModel.action ? 'СУПЕР ЦІНА - '+this.selectModel.action:'Знижка 30%('+getDiscount(this.selectModel.cost)+' грн )'):'НЕМА (АКЦИИ)',
      comment:'Коментар та побажання - ' + this.form.comment,
      phone:this.form.phone,
    }
    this.showCardOrder = false;
    this.serverOrder.sendOrder(orderCard, (responseTelegramBot: any) => {
      this.saveFormToStorage();
      this.basketServices.data = {
        ...this.form, ...this.selectModel
      }
      this.basketServices.save()
      this.hide();
    })
  }

  ngAfterViewInit(): void {
  }

  public hide() {
    this.showCardOrder = false;
    this.onClose.emit(false);

    this.clear();
  }

  public timeConverter(UNIX_timestamp: any) {
    let a = new Date(UNIX_timestamp * 1000);
    let months = [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Серпень',
      'Жовтень',
      'Листопад',
      'Грудень'
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = date + ' ' + month + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  public saveFormToStorage() {
    this.form.model = {
      id: randomId(),
      createAt: Date.now(),
      name: this.selectModel.name,
      cost: this.selectModel.cost,
      desc: this.selectModel.desc,
      costDiscount: this.selectModel.costDiscount,
      srcPreview: this.selectModel.srcPreview,
    };
    // this.cd.setStorageData('form', this.form);

  }

  public initFormStorage() {
    /*
        let formStorage = this.cd.getStorageData(this.formStorageName);
        if (formStorage) {
          this.form = formStorage;
          this.cd.form = formStorage
        }*/
  }

  private clear() {
    this.form.nameClient = '';
    this.form.phone = '';
    this.form.comment = '';
    this.form.checked = [];
    this.form.stringChecked = '';
  }

}
